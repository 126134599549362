<template>
  <div class="bg-gray-50 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {{ dict.sign_up_form_title }}
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">
                        {{ dict.Email_address }}
                    </label>
                    <div class="mt-1">
                        <input v-model="email" :placeholder="dict.sign_up_email_ph" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">
                        {{ dict.sign_up_name }}
                    </label>
                    <div class="mt-1">
                        <input v-model="name" :placeholder="dict.sign_up_name" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ dict.Password }}
                    </label>
                    <div class="mt-1">
                        <input v-model="password" :placeholder="dict.sign_up_pwd_ph" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ dict.confirm_password }}
                    </label>
                    <div class="mt-1">
                        <input v-model="password_confirm" :placeholder="dict.sign_up_pwd_re_ph" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                <button @click="signMeUp($event)" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ dict.sign_up }}
                </button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions,mapMutations} from 'vuex';
import Helpers from "../../common/Helpers";
export default {
  name: "SignUpForm",
  props: {
    dict: Object,
  },
  data(){
      return {
          email:'',
          name:'',
          password:'',
          password_confirm:'',
      }
  },
  methods: {
      ...mapActions(['SignUpAction']),
      ...mapMutations(['Notify']),
      signMeUp: function(e){
          e.preventDefault();
          this.SignUpAction({
              email: this.email,
              name: this.name,
              password: this.password,
              password_confirm: this.password_confirm,
          }).then( res =>{
            if(res.code === 0){
                // Sign up OK
                // Save email in the local storage
                localStorage.email = this.email;
                localStorage.uid = res.data.id;
                localStorage.vip_expired_at = res.data.vip_expired_at;
                const msg = Helpers.successNotification(this.dict.sign_up_success + this.name);
                this.Notify(msg);
                this.$router.push({
                    path:'/'
                });
            }else{
                const msg = Helpers.errorNotification('Error!' + res.data.msg);
                this.Notify(msg);
            }
          }).catch(err => {
            // 登陆失败
            const msg = Helpers.errorNotification(err.msg);
            this.Notify(msg);
          });
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
