<template>
    <div class="about">
        <SignUpForm :dict="dict"></SignUpForm>
    </div>
</template>

<script>
import SignUpForm from '../components/User/SignUpForm.vue';
import {mapGetters} from 'vuex';
export default {
  name: "SignUp",
  components: {
      SignUpForm
  },
  computed:{
      ...mapGetters(['dict'])
  }
};
</script>
